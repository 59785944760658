@import url('https://fonts.cdnfonts.com/css/made-tommy-outline');
@import url('https://fonts.cdnfonts.com/css/dm-sans');
@import url('https://fonts.cdnfonts.com/css/inter');


.body {
    font-family: 'MADE TOMMY', sans-serif;
}

.typography {
    font-family: 'MADE TOMMY', sans-serif;
}

.getstartedbackground {
    background-image: url(../../asset/newhomepageimage.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}

.error {
    color: red;
    font-family: 'MADE TOMMY', sans-serif;
    font-size: x-small;
    margin-top: -3%;
}

.getstartefirstrowlogo {
    background: #FFF;
    min-height: 100vh;

}

.getstartedrowlogo {
    height: 25vh;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.getstartedcollogo {
    /* height: 20vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.getstartedtext {
    margin-bottom: 0;
}

.getstartedcolpad {
    padding: 0px 10em 20px 10em;
    margin-bottom: 1.5em;
}

.getstartedcolpadotp {
    padding: 0px 10em 20px 10em;
    margin-bottom: 1.5em;
}

.joinbakery {
    color: #6B7280;
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 133.333% */
}


.buttonicons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    color: rgba(0, 0, 0, 0.54);
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.signinlink {
    color: #998C16;
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline;
}

.terms {
    color: #6B7280;
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    /* 133.333% */
}

.getstartedcolpadterms {
    margin-top: 5%;
    margin-bottom: 1.5em;
    display: flex;
    justify-content: center;
}

.signupbutton {
    color: #fff;
    background: var(--New-Primary, #252A4D);
    font-size: small;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.resendotpbutton {
    color: #fff;
    background: var(--New-Primary, #252A4D);
    font-size: small;
    margin-top: 2em;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.signupbutton:hover {
    color: #fff;
    background-color: var(--New-Primary, #2445CD);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.otpinputs {
    fill: rgba(0, 124, 245, 0.02);
    strokeWidth: 1px;
    stroke: #E5E7EB;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.00) inset;
    width: 57px;
    height: 56px;
    text-align: center;
    font-size: 22px;
    margin: 0 5px;
}

.otpinputs::selection {
    font-size: 16px;
}

.otpLayoutinputs {
    display: inline-flex;
    align-items: flex-start;
    gap: 22px;
}

.onboardingbackgimg {
    background-image: url(../../asset/onboardingbackgroundimage.jpg);
    /* Full height */
    /* height: 100%; */
    /* Center and scale the image */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding: 1.5em;
}

.onboardingcol {
    /* background-color: #fff; */
    min-height: 94vh;
    border-radius: 10px;
    background: #FFF;
    padding-bottom: 2.5em;
}

.onboardingheader {
    height: 18vh;
    padding-top: 3em;
    padding-left: 4em;
}

.steppercounttext {
    color: #9C9AA5;
    font-family: "MADE TOMMY";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
}

.bestdescribetext {
    color: #26203B;
    font-family: "MADE TOMMY";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* text-align: center; */
}

.settinguprow {
    background-color: white;
    min-height: 93vh;
    min-width: 90vw;
    flex-direction: column;
    border-radius: 10px;
}

.settingupaccouttext {
    color: #1D1D1D;
    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.settingupaccoutsectext {
    color: #5D5D5D;
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.settingupaccoutspin {
    margin-top: 6em;
}

.settingupaccoutsuccessful {
    margin-top: 1em;
}

.settinupsecuritycol {
    /* background-color: #fff; */
    /* height: 91vh; */
    margin-top: 6em;
}

.settinuptextcol {
    /* background-color: #fff; */
    /* height: 91vh; */
    margin-bottom: 1.5em;
}

.welcomebestdescriberow {
    display: flex;
    justify-content: center;
    padding: 0em 10em 0em 10em;
}

.bestdescribeoptionrow {
    display: flex;
    justify-content: center;
    padding: 0em 6em 0em 6em;
    margin: 4em 0em 0em 0em;
}

.bestdescribeoptiondescription {
    color: #9C9AA5;
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bestdescribeoptiontitle {
    color: #111827;
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.5em;
}

.ant-radio-checked .ant-radio-inner {
    background-color: #998C16 !important;
    border-color: #998C16 !important;
  }

.bestdescriberadio {
    color: #998C16;
    background-color: #998C16;
}

.welcomebestdescribetext {
    color: #8C8C8C;
    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.aboutbusinesscard {
    margin: 2em 7em 2em 7em;
    width: 100%;
}

.aboutbusinessformlabel {
    color: var(--Light-StormGrey-50, #6B7888);
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: -2em;
    /* 142.857% */
}

.aboutbusinessformitem {
    margin-top: -1em;
}

.addanotherphone {
    margin-top: -2em;
    display: flex;
    justify-content: flex-end;
}

.addanotherphonebutton {
    border: none;
    background-color: transparent;
    color: #5E4E3F;
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.skipsteponb {
    margin-bottom: -1em;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
}

.skipsteponbbutton {
    color: #979C31;
    font-family: Inter;
    font-size: small;
    background-color: transparent;
    border-color: transparent;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 215.385% */
    letter-spacing: -0.143px;
}

.addanothersocialbuttonfi {
    margin-left: -2em;
}

.radioCarddiv {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.radioOnbCard {
    margin-bottom: 2em;
    border-radius: 16.353px;
}

.radioImageDescdiv {
    display: flex;
    /* justify-content: space-between; */
}

.radioImageIcondiv {
    width: 5vw;
    /* height: 59px; */
    flex-shrink: 0;
    border-radius: 10px;
    background: #998C16;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radioImageIcondiv2 {
    width: 5vw;
    /* height: 59px; */
    border-radius: 10px;
    background: #F8F7F7;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radiotitledescdiv {
    padding: 0em 2em 0em 2em;
}

.radiogrouponbdiv {
    position: absolute;
    top: 16px;
    right: 16px;
}

.phoneInputDiv {
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 8px
}

/* onboarding button css styling */
.onboardingbuttonnextandprev {
    color: #111827;
    /* font-family: "Circular Std"; */
    /* font-family: "MADE TOMMY"; */
    font-size: medium;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
}

/* default css styling */
.PhoneInputInput {
    /* flex: 1 1;
    min-width: 0; */
    border: none;
}

.dynamic-delete-button {
    position: relative;
    top: 0px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.onbnextandprevbutton {
    margin-top: 24;
    display: flex;
    justify-content: space-between;
    padding: 0 5em 0 5em;
}

@media only screen and (max-width: 1309px) {
    /* .otpinputs {
        fill: rgba(0, 124, 245, 0.02);
        strokeWidth: 1px;
        stroke: #E5E7EB;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.00) inset;
        width: 70px;
        height: 56px;
        text-align: center;
        font-size: 22px;
        margin: 0 5px;
    }
    
    .otpinputs::selection {
        font-size: 16px;
    }
    
    .otpLayoutinputs {
        display: inline-flex;
        align-items: flex-start;
        gap: 22px;
    } */

    .getstartedcolpadotp {
        padding: 0px 3em 20px 3em;
        margin-bottom: 1.5em;
    }
}

@media only screen and (max-width: 992px) {
    .getstartedbackground {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .bestdescribeoptionrow {
        padding: 0em 5em 0em 5em;
        margin: 3em 0em 0em 0em;
    }
}

@media only screen and (max-width: 620px) {
    .aboutbusinessformitem {
        margin-top: -1em;

    }

    .aboutbusinessformitemmob {
        margin-bottom: 0.4em;
        font-size: smaller;
    }

    .settingupaccouttext {
        font-size: small;
    }

}

@media only screen and (max-width: 590px) {
    .bestdescribetext {
        font-size: x-large;
        /* text-align: center; */
    }

    .welcomebestdescriberow {
        padding: 0em 7em 0em 7em;
    }

    .welcomebestdescribetext {
        font-size: smaller;
    }

    .aboutbusinesscard {
        margin: 2em 3em 2em 3em;
        width: 100%;
    }

    .aboutbusinessformlabel {
        color: var(--Light-StormGrey-50, #6B7888);
        font-family: "MADE TOMMY";
        font-size: smaller;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0em;
        /* 142.857% */
    }

    .onbnextandprevbutton {
        padding: 0 2em 0 2em;
    }

    .radioImageDescdiv {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .radioImageIcondiv {
        width: 15vw;
        height: 59px;
        /* flex-shrink: 0; */
        border-radius: 10px;
        background: #998C16;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5em;
    }

    .radioImageIcondiv2 {
        width: 15vw;
        height: 59px;
        /* flex-shrink: 0; */
        border-radius: 10px;
        background: #F8F7F7;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5em;
    }

    .radiotitledescdiv {
        padding: 0em 0em 0em 0em;
    }
}

@media only screen and (max-width: 540px) {
    .getstartedcolpad {
        padding: 0px 3em 20px 3em;
        margin-bottom: 1em;
    }

    .settingupaccouttext {
        font-size: smaller;
    }
    
    .settingupaccoutsectext {
        font-size: smaller;
    }
}

@media only screen and (max-width: 460px) {
    .getstartedcolpadotp {
        padding: 0px 1em 20px 1em;
        margin-bottom: 1.5em;
    }
}

@media only screen and (max-width: 450px) {
    .bestdescribetext {
        font-size: large;
        /* text-align: center; */
    }

    .welcomebestdescriberow {
        padding: 0em 3em 0em 3em;
    }

    .welcomebestdescribetext {
        font-size: smaller;
    }

    .aboutbusinesscard {
        margin: 2em 2.5em 2em 2.5em;
        width: 100%;
    }

    .radiotextonb {
        color: #1F1F1F;
        font-family: 'Inter', sans-serif;
        font-size: x-small;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .bestdescribeoptiontitle {
        font-size: x-small;
    }
    .bestdescribeoptiondescription {
        font-size: x-small;
    }

    .getstartedtext {
        font-size: small;
    }

}

@media only screen and (max-width: 400px) {
    .otpLayoutinputs {
        display: inline-flex;
        align-items: flex-start;
        gap: 8px;
    }

    .otpinputs {
        width: 57px;
        height: 36px;
    }

    .aboutbusinessformlabel {
        color: var(--Light-StormGrey-50, #6B7888);
        font-family: "MADE TOMMY";
        font-size: x-small;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0em;
        /* 142.857% */
    }

    .settingupaccouttext {
        font-size: xx-small;
    }

    .settingupaccoutsectext {
        font-size: x-small;
    }

    .onbnextandprevbutton {
        padding: 0 1em 0 1em;
    }
}

@media only screen and (max-width: 340px) {
    .bestdescribetext {
        font-size: medium;
        /* text-align: center; */
    }

    .welcomebestdescriberow {
        padding: 0em 1em 0em 1em;
    }

    .welcomebestdescribetext {
        font-size: x-small;
    }

    .aboutbusinessformlabel {
        color: var(--Light-StormGrey-50, #6B7888);
        font-family: "MADE TOMMY";
        font-size: xx-small;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0em;
        /* 142.857% */
    }

    .settingupaccoutsectext {
        font-size: xx-small;
    }

}