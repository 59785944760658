@import url('https://fonts.cdnfonts.com/css/made-tommy-outline');
@import url('https://fonts.cdnfonts.com/css/dm-sans');
@import url('https://fonts.cdnfonts.com/css/inter');
@import url('https://fonts.cdnfonts.com/css/poppins');

:root {
    --New-Primary: #252A4D;
    /* Example color code */
}

.headercol1 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    /* gap: 20px; */
    /* align-self: center; */
    padding: 1% 0 0 3%;
    gap: 1em;
    /* font-family: 'MADE TOMMY', sans-serif; */
    /* margin-bottom: auto; */
}

.headercol2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    /* align-self: center; */
    margin-top: 1%;
    /* font-family: 'MADE TOMMY', sans-serif; */
    /* margin-bottom: auto; */
}

.headercoldtext1 {
    color: var(--Black-60, #45464E);
    font-family: "MADE TOMMY";
    font-size: medium;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: auto;
    margin-bottom: auto;
}

.headercoldtext2 {
    color: var(--Black-60, #45464E);
    font-family: "MADE TOMMY";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: auto;
    margin-bottom: auto;
}

.dashboardtitlescol {
    padding: 4em 0em 2em 0em;
}

/* // */


.dashboardnibblesbutton1Heading1 {
    background-color: #E7E9CB;
    fill: #E7E9CB;
    opacity: 0.6;
    filter: drop-shadow(0px 11.508px 17.261px rgba(135, 125, 200, 0.25));
    /* width: 91.294px; */
    height:35px;
    flex-shrink: 0;
}

.dashboardnibblesbutton1typogHeading1 {
    color: #515409;
    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 12.33px */
    letter-spacing: 0.117px;
    margin-right: 1em;
}

.dashboardnibblesbutton2Heading2 {
    margin-left: 1em;
    background-color: #F6EFBE;
    fill: #F6EFBE;
    opacity: 0.6;
    filter: drop-shadow(0px 11.508px 17.261px rgba(135, 125, 200, 0.25));
    /* width: 91.294px; */
    height: 35px;
    flex-shrink: 0;
}

.dashboardnibblesbutton2typogHeading2 {
    color: #725200;
    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 12.33px */
    letter-spacing: 0.117px;
    margin-right: 1em;
}


/* // */
.dashboardtitlestypog {
    color: #323142;
    font-family: "MADE TOMMY";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 21px */
    letter-spacing: 0.2px;
}

.dashboardnibblesbuttonscol {
    display: flex;
    justify-content: space-between;
}

.dashboardnibblesbutton1 {
    background-color: #BAC06A;
    fill: #BAC06A;
    opacity: 0.6;
    filter: drop-shadow(0px 11.508px 17.261px rgba(135, 125, 200, 0.25));
    /* width: 91.294px; */
    height: 28.182px;
    flex-shrink: 0;
}

.dashboardnibblesbutton1typog {
    color: #7B8017;
    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 8.22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 12.33px */
    letter-spacing: 0.117px;
    margin-right: 1em;
}

.dashboardnibblesbutton2 {
    margin-left: 1em;
    background-color: #F8E659;
    fill: #F8E659;
    opacity: 0.6;
    filter: drop-shadow(0px 11.508px 17.261px rgba(135, 125, 200, 0.25));
    /* width: 91.294px; */
    height: 28.182px;
    flex-shrink: 0;
}

.dashboardnibblesbutton2typog {
    color: #D69900;
    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 8.22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 12.33px */
    letter-spacing: 0.117px;
    margin-right: 1em;
}

.dashboardnibblescardbutton1 {
    height: 22px;
    flex-shrink: 0;
    border-radius: 2.327px;
    background: #E7E9CB;
    margin-bottom: 1em;
}

.dashboardnibblescardbutton2 {
    height: 22px;
    flex-shrink: 0;
    border-radius: 2.327px;
    background: #FDF6C5;
    margin-bottom: 1em;
}

.retailbutton {
    border-radius: 6px;
    background: #2B315A;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
    color: var(--Theme-White-Default, #FFF);
    /* Button/Base */
    font-family: Inter;
    /* font-size: 12px; */
    font-style: normal;
    font-weight: 600;
}

.retailwelcometext {
    color: #323142;
    font-family: "MADE TOMMY";
    font-size: 18.367px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 27.55px */
    letter-spacing: 0.262px;
}

.retailsupportdiv {
    display: flex;
    justify-content: flex-end;
}

.dashboardtitlescardtypog {
    color: #323142;
    font-family: MADE TOMMY;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 15.75px */
    letter-spacing: 0.15px;
    margin-bottom: 2%;
}

.dashboardcarddescriptiontypog {
    color: #004005;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 13.5px */
    letter-spacing: 0.197px;
    text-align: center;
}

.dashboardcardpriceandcartdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addtocartbutton {
    border-radius: 3.492px;
    background: #252A4D;
    color: #fff;
    /* font-size: large; */
}

.spinner-containers {
    margin-left: auto;
    margin-right: auto;
}

.productcard {
    border-radius: 12.938px;
    border: 0.539px solid #F4F4F6;
    background: #FFF;
    box-shadow: 2.562px 30.747px 15.373px 0px rgba(229, 229, 229, 0.70);
    /* width: 182.25px; */
    /* height: 174px; */
    margin-bottom: 2em;
}

.productdescriptionmodaltitle {
    color: var(--Gray-Scale-Gray-900, #1A1A1A);
    font-family: "MADE TOMMY";
    font-size: x-large;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 31.992px */
}

.productdescriptionmodalpriceview {
    color: #004005;
    font-family: "MADE TOMMY";
    font-size: 17.774px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 26.66px */
}

.productdescriptionmodaldescriptionview {
    color: var(--Gray-Scale-Gray-500, #808080);
    font-family: Poppins;
    font-size: 10.368px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 15.552px */
}

.productdescriptionmodalqtybtn {
    /* border-radius: 125.896px; */
    background: var(--Gray-Scale-Gray-50, #F2F2F2);
}

.productdescriptionmodalqtybtndiv {
    /* border-radius: 125.896px; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 125.896px;
    border: 0.741px solid var(--Gray-Scale-Gray-100, #E6E6E6);
    background: var(--Gray-Scale-White, #FFF);
    padding: .4em;
}

.productdescriptionmodaladdtocartbtn {
    border-radius: 31.844px;
    background: var(--New-Primary, #252A4D);
    color: #FFF;
    /* Ensure the text color is set to white on the default state */
}

.productdescriptionmodaladdtocartbtn:hover {
    background: var(--Gray-Scale-White, #FFF);
    color: #000;
    /* Set the text color to black on hover */
}

.carousel-container {
    width: 100%;
    height: 100%;
}

.carousel-container img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
}

.carousel-container-modal {
    width: 100%;
    height: 100%;
}

.carousel-container-modal img {
    width: 100%;
    /* height: 130px; */
    object-fit: cover;
    border-radius: 10px;
}

.carousel-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.dashboardcardscol {
    margin-top: 1.5em;
}

.dashboardcardcontent {
    margin-top: .5em;
}

.verifyaccountbtn {
    display: flex;
    padding: 6.112px;
    justify-content: center;
    align-items: center;
    gap: 6.112px;
    border-radius: 6.112px;
    border: 0.611px solid #28303F;
    background: #FFF4D1;
    color: #53545C;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.verifyalert {
    width: 100%;
    height: 4.5%;
    flex-shrink: 0;
    background: #FFF4D1;
    padding: .5em 5em .5em 5em;
}

.verifyicon {
    margin-right: 1em;
}

.uploadComplianceImage {
    border-radius: 4.788px;
    background: var(--New-Primary, #252A4D);
    color: #FFF;
}

.compliancestatusbtn {
    color: #888C3F;
    border-radius: 6.384px;
    border: 0.798px solid var(--Nibbles-Baby-Blue, rgba(85, 112, 241, 0.08));
    background: rgba(143, 131, 21, 0.08);
    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 11.171px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.959px;
    /* 142.857% */
    letter-spacing: 0.08px;
}

.addcomplianceloc {
    color: #fff;
    border-radius: 6px;
    background: #A4B506;
}

.addcompliancelocDiv {
    display: flex;
    justify-content: flex-end;
}

.uploadcaccard {
    display: flex;
    padding: 6.384px 9.575px;
    justify-content: space-between;
    align-items: center;
    /* gap: 11.171px; */
    /* align-self: stretch; */
    border-radius: 6.384px;
    border: 0.798px solid rgba(190, 192, 202, 0.50);
}

.innerUploadcacdiv {
    display: flex;
}

.uploadcacbtn {
    color: #4B56A4;
    font-family: "MADE TOMMY";
    font-size: smaller;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 3.991px;
    background: rgba(75, 86, 164, 0.10);
}

.chooseareaofcovbtn {
    color: #fff;
    font-family: "MADE TOMMY";
    font-size: smaller;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 3.339px;
    background: #4B56A4;
}

.compliancealert {
    color: var(--New-Primary, #252A4D);
    font-family: "MADE TOMMY";
    /* font-size: 11.171px; */
    font-style: normal;
    flex-shrink: 0;
    font-weight: 400;
    line-height: 15.959px;
    /* 142.857% */
    letter-spacing: -0.067px;
    border-radius: 4.788px;
    border: 0.798px solid rgba(47, 84, 235, 0.20);
    background: #F5F9FF;
}


.carttextproductnametypography {
    color: #323142;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 12.894px */
    margin: 0em;
}

.carttextproductpricetypography {
    color: var(--Text-Light, #5B6D75);
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 11.902px */
    margin: 0em;
}

.carttextproductQpricetypography {
    color: var(--New-Primary, #252A4D);
    /* text-align: right; */
    font-family: "MADE TOMMY";
    font-size: auto;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 15.87px */
    margin: .4em 0em auto 0em;
}

.cartpickingwhenready {
    color: var(--Color-Gray-1, #A098AE);
    font-family: "MADE TOMMY";
    font-size: smaller;
    font-style: normal;
    font-weight: 400;
    line-height: 15.744px;
    /* 171.429% */
    padding-top: 1em;
}

.cartnearestlocation {
    color: var(--Black-60, #45464E);
    font-family: "MADE TOMMY";
    font-style: normal;
    font-size: smaller;
    font-weight: 400;
    line-height: normal;
    margin-top: auto;
    margin-bottom: auto;
}

.cartnearestlocationbutton {
    border-radius: 3.507px;
    border: 0.438px solid var(--New-Primary, #252A4D);
    color: var(--New-Primary, #252A4D);
    font-family: Poppins;
    font-size: smaller;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cartadddetailbutton {
    border-radius: 3.507px;
    border: 0.438px solid var(--New-Primary, #252A4D);
    color: var(--Color-Black, #2E2E2E);
    font-family: "MADE TOMMY";
    font-size: smaller;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 1em;
}

.cartpricesummariesdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0em;
}

.cartpricesummariestypog1 {
    color: #717171;
    font-family: "MADE TOMMY";
    font-size: small;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 13.89px */
}
.cartprices-total-txt{
    color: #252A4D;
    font-weight: 500;
    line-height: 140%; /* 15.875px */
}

.cartcouponbutton {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    padding: 0 15px;
    align-items: center;
    /* padding-top: 1.2em; */
    border-radius: 10.926px;
    border: 0.683px solid #BDC167;
    background: #F5F5F5;
    /* backdrop-filter: blur(5.463080883026123px); */
    color: var(--Color-Black, #2E2E2E);
    font-family: Poppins;
    font-size: 9.56px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.placeordercartbutton {
    display: flex;
    width: 100%;
    height: 39px;
    padding: 9.919px;
    justify-content: center;
    align-items: center;
    gap: 5.668px;
    flex-shrink: 0;
    border-radius: 5.668px;
    background: #323D87;
    box-shadow: 0px 5.668px 17.003px 0px rgba(47, 53, 91, 0.40);
    color: var(--Base-BG, #FAFAFA);
    font-family: "MADE TOMMY";
    font-size: 9.919px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 13.886px */
}

.paystackpaymentbutton {
    display: flex;
    width: 100%;
    height: 39px;
    padding: 9.919px;
    justify-content: center;
    align-items: center;
    gap: 5.668px;
    flex-shrink: 0;
    border-radius: 5.668px;
    background: #323D87;
    box-shadow: 0px 5.668px 17.003px 0px rgba(52, 59, 101, 0.4);
    color: var(--Base-BG, #FAFAFA);
    font-family: "MADE TOMMY";
    font-size: 9.919px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 13.886px */
}

.paystackpaymentbutton:hover {
    display: flex;
    width: 100%;
    height: 39px;
    padding: 9.919px;
    justify-content: center;
    align-items: center;
    gap: 5.668px;
    flex-shrink: 0;
    border-radius: 5.668px;
    border-color: #323D87;
    background: #FAFAFA;
    box-shadow: 0px 5.668px 17.003px 0px rgba(47, 53, 91, 0.40);
    color: var(--Base-BG, #323D87);
    font-family: "MADE TOMMY";
    font-size: 9.919px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 13.886px */
}

.cartpricesummariesplaceorderdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
}


.orderHistory {
    display: flex;
    width: 79.048px;
    height: 32.937px;
    padding: 6.587px;
    justify-content: center;
    align-items: center;
    text-align: right;
    font-family: "MADE TOMMY";
    font-size: 11.528px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 17.292px */
    letter-spacing: 0.165px;
    gap: 3.294px;
    border-radius: 6.587px;
}

.completed {
    background: #CBF1DA;
    color: #2D9556;
}

.rejected {
    color: #FF6A6A;
    background: #FFDEDE;
}

.in-progress {
    background: #FFF4D1;
    color: #3A3C00;
}

.orderHistory-text {
    color: #1A202C;
    font-family: "MADE TOMMY";
    font-size: 11.528px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 17.292px */
    letter-spacing: 0.165px;
}

.orderHistory-view-details {
    color: var(--New-Primary, #252A4D) !important;
    font-family: Poppins !important;
    font-size: 12.655px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150% !important;
    /* 18.983px */
    text-decoration-line: underline !important;
}


/* Custom styles for pagination */
.ant-pagination {
    margin-top: 20px;
}

/* Styling for pagination options */
.ant-pagination-options {
    margin-right: 20px;
}

/* Custom styling for table rows */
.ant-table-tbody>tr:nth-child(even) {
    background-color: #f5f5f5;
}

/* Custom styling for table cells */
.ant-table-cell {
    padding: 10px;
}

.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orderHistory-time {
    color: #718096;

    font-family: "MADE TOMMY";
    font-size: 11.528px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 17.292px */
    letter-spacing: 0.165px;
}

.ant-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ant-table {
    background-color: lightblue;
    /* Example style */
}

.ant-steps-item-title {
    color: var(--Branding-Success-Dark, #2C742F) !important;
    text-align: center !important;
    /* Body Small/Body Small, 400 */
    font-family: Poppins !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    /* 21px */
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #4CAF50;
    /* Green background for completed icons */
    border-color: #4CAF50;
    /* Green border for completed icons */
}

.ant-steps-item-process .ant-steps-item-icon {
    background-color: #4CAF50;
    /* Blue background for current step icon */
    border-color: #4CAF50;
    /* Blue border for current step icon */
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #ffeb3b;
    /* Yellow background for pending steps */
    border-color: #ffeb3b;
    /* Yellow border for pending steps */
}

.ant-steps-item-finish .ant-steps-item-title,
.ant-steps-item-process .ant-steps-item-title,
.ant-steps-item-wait .ant-steps-item-title {
    color: #333;
    /* Change text color if needed */
}

.ant-steps-item-finish .ant-steps-item-tail::after,
.ant-steps-item-process .ant-steps-item-tail::after {
    background-color: #4CAF50;
    /* Green line for completed steps */
}


/* Admin: Category page */
.inactive {
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;

    border-radius: 17.574px;
    background: rgba(250, 95, 95, 0.08);

    color: #FF3A44;

}

.active {
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    color: #059646;

    border-radius: 17.574px;
    background: rgba(5, 150, 70, 0.08);
}

.pro-inactive {
    display: flex;
    padding: 4.391px 2.586px;
    /* justify-content: center;
    align-items: center; */
    gap: 7.988px;

    border-radius: 17.574px;
    background: rgba(250, 95, 95, 0.08);

    color: #FF3A44;

}

.prod-active {
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    color: #059646;

    border-radius: 17.574px;
    background: rgba(5, 150, 70, 0.08);
}

.pending {
    color: #E8A010;

    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    background: #FEF2E5;
}

.approved {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #059646;
    background: rgba(5, 150, 70, 0.08);
}

.rejected {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #FF3A44;
    background: rgba(250, 95, 95, 0.08);

}

.canceled {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #928EF0;
    background: #BFBCFF4D;

}


.ant-tabs-tab-btn {
    color: var(--color-text-tab-normal, #6C6C89);
    font-family: Inter;
    font-size: 13.534px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.201px;
    /* 171.429% */
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: #252A4D;
}

.ant-tabs-tab-btn.ant-tabs-tab-active {
    color: #252A4D;

}

.ant-pagination-item.ant-pagination-item-active {
    background-color: #252A4D;
    color: white;
    border: 1px solid #252A4D;
    border-radius: 30px;

}

.ant-pagination-item.ant-pagination-item-active:hover {

    border-radius: 30px;

}

@media only screen and (max-width: 769px) {
    .dashboardtitlescol {
        padding: 2em 0em 1em 0em;
    }
}

@media only screen and (max-width: 590px) {
    .accountverificationtext {
        font-size: xx-small;
    }

    .dashboardtitlescol {
        padding: 1em 0em 1em 0em;
    }
}

@media only screen and (min-width: 1200px) {
    .no-request {
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 500px) {
    .innerUploadcacdiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .uploadcaccard {
        display: flex;
        flex-direction: column;
    }

    .fileiconcac {
        width: 20%;
        height: 20%;
        margin: 0em auto 0em auto;
    }

    .compliancecardtitletext {
        font-size: x-small;
    }

    .compliancestatusbtn {
        font-size: xx-small;
    }

    .headercoldtext1 {
        font-size: small;
    }

    .headercoldtext2 {
        font-size: xx-small;
    }
}


/* .ant-btn.css-dev-only-do-not-override-1rqnfsa.ant-btn-primary{
    background-color: #252A4D;
    color: white;
    border: 1px solid #252A4D;
}

.ant-btn.css-dev-only-do-not-override-1rqnfsa.ant-btn-primary:hover{
    background-color: #656886;
   
}

.ant-btn.css-dev-only-do-not-override-1rqnfsa.ant-btn-default{
    color: #252A4D;

}

.ant-btn.css-dev-only-do-not-override-1rqnfsa.ant-btn-default:hover{
    color: #252A4D;
    border-color: #252A4D;
 */
/* } */

.ant-row.ant-form-item-row.css-dev-only-do-not-override-1rqnfsa:hover {
    color: #252A4D;
}


/* Order */
.received {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #4680F1;
    background: rgba(70, 128, 241, 0.20);
}

.processing {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #553F2C;
    background: rgba(85, 63, 44, 0.20);
}

.confirmed {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #AA8100;
    background: rgba(170, 129, 0, 0.20);
}

.loading {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #4E49D7;
    background: rgba(78, 73, 215, 0.20);
}

.available-for-pickup {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #2D9556;
    background: rgba(45, 149, 86, 0.15);
}

.archived {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #979797;
    background: rgba(151, 151, 151, 0.20);
}

.rejected-order {
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
    color: #FF3A44;
    background: rgba(255, 58, 68, 0.10);
}



/* Order Details */

.order-proc {
    display: flex;
    padding: 5.195px 7.792px 5.195px 5.195px;
    align-items: flex-start;
    gap: var(--spacing-0, 0px);
    align-self: stretch;
    border-radius: 5.195px;
    border: 0.649px solid var(--color-icon-outline-secondary-disabled, #D1D1DB);

    display: flex;
    padding: var(--spacing-1, 4px) var(--spacing-2, 8px);
    align-items: center;
    gap: var(--spacing-0, 0px);

    color: var(--color-text-body, #121217);
    font-family: "MADE TOMMY";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.987px;
    /* 142.857% */
}

.hash-id {
    color: var(--New-Primary, #252A4D);
    font-family: "MADE TOMMY";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.basic-text {
    color: #514A41;
    font-family: "MADE TOMMY";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.basic-text-bold {
    color: var(--New-Primary, #252A4D);
    font-family: "MADE TOMMY";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.time-left {
    display: flex;
    width: 167px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    background: rgba(211, 215, 130, 0.37);
    color: #888D29;

}

.time-left-text {

    font-family: "MADE TOMMY";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.time-left-icon {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
}

.summary-tag {
    border-radius: 10px;
    background: #F9F8F8;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: var(--color-surface-dark-0, #000);

    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 12.33px */
    letter-spacing: 0.117px;

    border-radius: 10px;
    background: #F9F8F8;
    display: flex;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.approvalflow-tag {
    border-radius: 10px;
    border: var(--spacing-px, 1px) solid #909446;
    cursor: pointer;
    background: #F9F8F8;
    display: flex;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #909446;

    text-align: center;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 12.33px */
    letter-spacing: 0.117px;
}



/* Product Management */
.notification {
    display: flex;
    width: 100%;
    padding: 21px 18px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--spacing-4, 16px);
    border: var(--spacing-px, 1px) solid #D8C212;
    background: rgba(250, 251, 236, 0.52);
}

.notification-text {
    color: #131523;
    font-family: "MADE TOMMY";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.773px;
    /* 173.581% */
}

.title {
    width: 241px;
    color: var(--Black-60, var(--Nibbles-GRAY, #45464E));
    font-family: "MADE TOMMY";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card1 {
    border-radius: 20px;
    background: #FFF;
    width: 100%;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
    display: flex;
    padding: 31px 46px 32.53px 24px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30.5px;
}



.card1-title {
    color: var(--Black-60, var(--NIBBLES-GRAY, #45464E));
    font-family: "MADE TOMMY";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card1-card {
    border-radius: 20px;
    width: 227px;
    height: 113.97px;
    padding-top: 40px;
    margin-right: 10%;
}

.card1-card-text {
    color: #3A3D44;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.98px;
    text-align: left;
    /* 157% */
    display: flex;
    /* width: 94px;
    height: 17px; */
    flex-direction: column;
}

.card1-card-text-review {
    color: #6C737F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.98px;
    /* 157% */
    width: 150px;
    height: 17px;
    margin-right: 10px;
    flex-direction: column;
    justify-content: center;
}

.card1-card-value {
    color: #111927;
    font-family: "Plus Jakarta Sans";
    font-family: "MADE TOMMY";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.pink {
    background: #FFE7E5;
}

.yellow {
    background: #FFF6D9;

}

.blue {
    background: rgba(0, 124, 245, 0.15);



}

.green {
    background: #E7FFF7;

}

.order-summary-card {
    width: 675px;
    height: 471px;
    /* flex-shrink: 0; */
    border-radius: 20px;
    background: #FFF;

    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
}

.daily-order-summary-card-bar {
    width: 100%;
    height: 15%;
    padding: 11.951px 18.87px;
    padding-top: 20px;
    gap: 5.691px;
    border-radius: 9.105px;
    background: rgba(0, 178, 7, 0.05);
}

.order-notification-text {
    color: #131523;
    font-family: "MADE TOMMY";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.773px;
    /* 173.581% */
}


.order-customer-type-text {
    margin-right: 5px;
    border-radius: 10%;
    width: 4px;
    height: 4px;
    /* 173.581% */
}

.order-customer-type-legend-text {
    margin-right: 2px;
    margin-left: 10px;
    border-radius: 10%;
    width: 4px;
    height: 4px;
    /* 173.581% */
}

.direct-customer-text {
    background-color: #7f2da5;
    /* 173.581% */
}

.independent-customer-text {
    background-color: #b9892e;
    /* 173.581% */
}

.internal-order-text {
    background-color: #328835;
    /* 173.581% */
}


.spiral-chart-legend-text {
    color: #6C737F;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    /* display: flex; */
    /* justify-content: center; */

}

.revenue-percentage {
    color: #06A561;

    font-family: Inter;
    font-size: 9.753px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.932px;
}

.revenue-text {
    color: #5A607F;
    font-family: Inter;
    font-size: 9.753px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.932px;
}

.avg-income-card {
    width: 262.5px;
    height: 102px;
    flex-shrink: 0;
    border-radius: 4.5px;
    background: #FFF;
    box-shadow: 0px 0.75px 3px 0px rgba(21, 34, 50, 0.08);
    border-radius: 7.5px;
    border: 0.75px solid #D9D9D9;
}

.avg-income-card-amount {
    color: #111927;
    font-family: "Plus Jakarta Sans";
    font-family: "MADE TOMMY";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

.refund-card {
    border-radius: 9.598px;
    border: 0.96px solid #FFF;
    background-image: url('../../asset/refundrequestbg.svg');
    background-size: cover;
    background-position: right;

}

.refund-card-title {
    color: #FFF;
    font-family: "MADE TOMMY";
    font-size: 16.7px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.115px;
    /* 126.438% */
}

.refund-card-text-white {
    color: #FFF;
    font-family: "MADE TOMMY";
    /* font-size: 16.7px; */
    /* font-style: normal;
    font-weight: 200; */
    /* line-height: 21.115px; */
    /* 126.438% */
}

.refund-card-text-yellow {
    color: #FFF289;
    font-family: "MADE TOMMY";
    font-size: 16.7px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.115px;
    height: 100%;
    /* 126.438% */
}

.review-refund-btn {
    border-radius: 9.598px;
    border: 0.96px solid #FFF;
    background: var(--New-Primary, #252A4D);
    flex-shrink: 0;
    display: flex;
    width: 100%;
    height: 49.134px;
    padding: 11.762px 30.245px;
    justify-content: center;
    align-items: center;
    gap: 13.442px;
    flex-shrink: 0;

    border-radius: 6.721px;
    border: 1.184px solid var(--color-background-cursor-white, #FFF);

    color: var(--color-background-cursor-white, #FFF);
    font-family: "MADE TOMMY";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 113.2%;
    /* 18.112px */
}

.review-refund-btn:hover {
    color: #f4fa76;
    box-shadow: 0px 5.106px 2.106px 0px rgba(176, 201, 87, 0.202);
}

.confirm-order-tag {
    /* width: 139px; */
    color: #514A41;

    font-family: "MADE TOMMY";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 160% */
    letter-spacing: -0.2px;
    /* margin-right: 40px; */
    align-items: start;
}

.confirm-order {
    /* width: 139px; */
    color: var(--New-Primary, #252A4D);
    font-family: "MADE TOMMY";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 145.455% */
    align-items: start;
    letter-spacing: -0.22px;
    align-content: start;

}

.order-flow-step-title {
    /* width: 139px; */
    color: var(--New-Primary, #252A4D);
    font-family: "MADE TOMMY";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 145.455% */
    align-items: start;
    letter-spacing: -0.22px;
    align-content: start;

}

.approve-comment {
    color: #100e0c;
    background: #F0FDF9;

    font-family: "MADE TOMMY";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: -0.1px;
    align-items: start;
}

.reject-comment {
    color: #100e0c;
    background: #FEF3F2;

    font-family: "MADE TOMMY";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: -0.1px;
    align-items: start;
}


.reject-button {
    color: #E25319;
    text-align: right;
    font-family: "MADE TOMMY";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8.424px;
    /* 105.297% */
    display: flex;
    width: 63.178px;
    padding: 5.265px 23.692px;
    justify-content: center;
    align-items: center;
    gap: 5.265px;

    border-radius: 2.632px;
    background: #F5DBE3;
}

.approve-button {
    color: #E25319;
    text-align: right;
    font-family: "MADE TOMMY";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.265px;
    border-radius: 3px;
    background: #2D9556;
    color: white;

    box-shadow: 0px 2.106px 2.106px 0px rgba(93, 170, 124, 0.30);
}

.approve-button:hover {
    background-color: #9d9e9f !important;
}

.reject-button:hover {
    background-color: #9d9e9f !important;
}

.custom-table {
    color: "#DEDEDE";
    font-weight: 600;
    font-size: 11px !important;

}

.custom-table .ant-table-cell {
    color: "#DEDEDE";
    font-weight: 600;
    font-size: 11px !important;

}

.custom-table .ant-table-thead .ant-table-cell {
    background-color: #F8F8F8;
    color: #45464E;
    text-transform: uppercase;
    font-size: 11px;

}

.card-summary {
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    padding: 20px;
    min-height: 300px;
    overflow-y: auto;
    box-sizing: border-box;
}

.card-title {
    font-size: 11px;
    font-weight: 600;
    color: #666;
}

.payment-title {
    color: #666666;
    text-transform: capitalize;
    font-size: 12px;
}

.payment-amount {
    color: #666;
    font-weight: 500;
    font-size: 12px;

}

.payment-total {
    color: #1A1A1A;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 14px;

}

.button {
    background: var(--New-Primary);
    color: #fff;
    padding: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 12px;

}

.pickup-title {
    color: #45464E;
    font-size: 12px;

}

.pickup-desc {
    color: #A098AE;
    width: 100%;
    font-size: 12px;

}

.empty-note-text {
    color: #45464E;
    font-weight: 500;
    font-size: 12px;

}

@media (max-width: 767px) {
    .hidden-mobile {
        display: none;
    }
}

@media (min-width: 767px) {
    .hidden-desktop {
        display: none;
    }
}

.order-id-title {
    font-size: 20px;
    color: "#1a1a1a";
    font-weight: 600;
}
.order-title-info {
    color: var(--Gray-Scale-Gray-700, #4D4D4D);
    font-family: "MADE TOMMY";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

.ordertimelinecourierdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12%;
}

.adminCount {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
    width: 359px;
    height: 154px;
    flex-shrink: 0;
}

.adminCountTitle {
    color: #111927;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* width: 250px; */
    height: 18px;
    flex-shrink: 0;
}

.adminCountNo {
    color: #111927;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 213px;
    height: 18px;
    flex-shrink: 0;
}

.customerStat {
    border-radius: 18.469px;
    background: #FFF;
    box-shadow: 0px 4.617px 20.316px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.462px rgba(0, 0, 0, 0.03);
    height: 100%;
    flex-shrink: 0;
}

.statTabContainer {
    color: #434E58;
    font-family: "MADE TOMMY";
    font-size: 7.459px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 11.188px */
    letter-spacing: 0.107px;
}

.totCust {
    color: #111927;
    font-family: Inter;
    font-size: 12.928px;
    font-style: normal;
    font-weight: 700;
    line-height: 20.298px;
    color: #111927;
    font-family: Inter;
    font-size: 12.928px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.298px;
    /* 157% */
}

.orangeDot {
    border-radius: 3.694px;
    background: #F79009;
    width: 7.388px;
    height: 7.388px;
    flex-shrink: 0;
}

.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.addOrder {
    border-radius: 3.953px;
    border: 1.5px solid var(--New-Primary, #252A4D);
    font-family: "MADE TOMMY";
    font-size: 12px;
    /* background-color: white; */
    gap: 2.635px;

    /* flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--New-Primary, #252A4D);
    font-style: normal;
    line-height: normal; */
}

.admin-staff-avatar {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
}

/* Custom scrollbar styles */
.custom-scroll .simplebar-scrollbar::before {
    background-color: #fff;
}

.custom-scroll2 .simplebar-scrollbar::before {
    background-color: var(--New-Primary);
}
.custom-scroll-user-dashboard .simplebar-scrollbar::before {
    background-color: rgb(198, 198, 198);
}

.submenuadmindb .ant-menu-submenu-title {
    gap: 10px !important
}




/* Custom scrollbar for Antd Modal content */
.application-modal .ant-modal-body {
    overflow-y: auto;
}

/* Custom scrollbar styles */
.application-modal .ant-modal-body::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.application-modal .ant-modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the scrollbar track */
}

.application-modal .ant-modal-body::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar thumb */
    border-radius: 20px;
    /* Rounded corners for the scrollbar thumb */
}

.application-modal .ant-modal-body::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color when the scrollbar thumb is hovered */
}

/* Firefox */
.application-modal .ant-modal-body {
    scrollbar-width: thin;
    /* "auto" or "thin" */
    scrollbar-color: #d5d5d5 #f1f1f1;
    /* Scrollbar thumb and track color */
}

.rejectBtn {
    background-color: #ef5d5d;
    color: #fff;
}

.rejectBtn:hover {
    background-color: #d04848;
    color: #fff
}

.nibbles-button {
    padding: 20px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.table-body-text {
    color: #353535;
    font-weight: 600;
}

.approved-green {
    color: #059646;
    background-color: #05964614;
    font-family: Inter;
    font-size: 9.586px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 6.391px 9.586px;
    justify-content: center;
    align-items: center;
    gap: 7.988px;
    border-radius: 17.574px;
}

.rejected-red {
    color: #FF3A44;
    background-color: #FA5F5F14;
}

.ant-form-item-label {
    position: relative;
}

.ant-form-item-required::before {
    content: '*';
    position: absolute;
    right: 0px;
    color: #ff4d4f;
    bottom: 0px;
}



/* Product Management */
/* .notification{
display: flex;
width: 100%;
padding: 21px 18px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
border-radius: var(--spacing-4, 16px);
border: var(--spacing-px, 1px) solid #D8C212;
background: rgba(250, 251, 236, 0.52);
} */


.notification-text {
    color: #131523;
    font-family: "MADE TOMMY";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.773px;
    /* 173.581% */
}

.dashboard-view-btn {
    color: #191660;
    font-weight: 700;
    font-size: 16px;
    background: transparent;
    border: none;
    box-shadow: none;
}

.dashboard-view-btn:hover {
    color: #4452ae !important;
}

.dashboard-view-btn-disabled {
    color: #818181 !important;
    font-weight: 700;
    font-size: 16px;
    background: transparent;
    border: none;
    box-shadow: none;
}

.dashboard-view-btn-disabled:hover {
    cursor: not-allowed;
}

.title {
    width: 241px;
    color: var(--Black-60, var(--Nibbles-GRAY, #45464E));
    font-family: "MADE TOMMY";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card1 {
    border-radius: 20px;
    background: #FFF;
    width: 100%;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
    display: flex;
    padding: 31px 46px 32.53px 24px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30.5px;
}



.card1-title {
    width: 241px;
    color: var(--Black-60, var(--NIBBLES-GRAY, #111927));
    font-family: "MADE TOMMY";
    font-style: normal;
    font-size: 16.7px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.card1-card {
    border-radius: 20px;
    width: 227px;
    height: 113.97px;
    padding-top: 40px;
    margin-right: 10%;
}

/* .card1-card-text {
    color: #6C737F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.98px;
    display: flex;
    width: 94px;
    height: 17px;
    flex-direction: column;
    justify-content: center;
} */

.card1-card-value {
    color: #111927;
    font-family: "Plus Jakarta Sans";
    font-family: "MADE TOMMY";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.order-summary-card {
    width: 675px;
    height: 471px;
    /* flex-shrink: 0; */
    border-radius: 20px;
    background: #FFF;

    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
}

.daily-order-summary-card-bar {
    width: 100%;
    height: 15%;
    padding: 11.951px 18.87px;
    padding-top: 20px;
    gap: 5.691px;
    border-radius: 9.105px;
    background: rgba(0, 178, 7, 0.05);
}

.order-notification-text {
    color: #131523;
    font-family: "MADE TOMMY";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.773px;
    /* 173.581% */
}


/* .spiral-chart-legend-text {
    /* color: #6C737F;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center; */
/* } */

/* .revenue {} */

.revenue-percentage {
    color: #06A561;

    font-family: Inter;
    font-size: 9.753px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.932px;
}

.revenue-text {
    color: #5A607F;
    font-family: Inter;
    font-size: 9.753px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.932px;
}

.avg-income-card {
    width: 262.5px;
    height: 102px;
    flex-shrink: 0;
    border-radius: 4.5px;
    background: #FFF;
    box-shadow: 0px 0.75px 3px 0px rgba(21, 34, 50, 0.08);
    border-radius: 7.5px;
    border: 0.75px solid #D9D9D9;
}

.avg-income-card-amount {
    color: #111927;
    font-family: "Plus Jakarta Sans";
    font-family: "MADE TOMMY";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

/* .refund-card {
    width: 352px;
    height: 261px;
    flex-shrink: 0;
    border-radius: 9.598px;
    border: 0.96px solid #FFF;
    background-image: url('../../asset/refundbg.png');
    background-position:right;

} */

.refund-card-title {
    color: #FFF;
    font-family: "MADE TOMMY";
    font-size: 16.7px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.115px;
    /* 126.438% */
}


/* .refund-card-text-yellow {
    color: #FFF289;
    font-family: "MADE TOMMY";
    font-size: 16.7px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.115px;
} */

.review-refund-btn {
    border-radius: 9.598px;
    border: 0.96px solid #FFF;
    background: var(--New-Primary, #252A4D);
    flex-shrink: 0;
    display: flex;
    /* width: 288.883px; */
    /* height: 49.134px; */
    /* padding: 11.762px 30.245px; */
    justify-content: center;
    align-items: center;
    /* gap: 13.442px;
flex-shrink: 0; */

    /* border-radius: 6.721px;
border: 1.184px solid var(--color-background-cursor-white, #FFF); */

    color: var(--color-background-cursor-white, #FFF);
    font-family: "MADE TOMMY";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 113.2%;
}

.confirm-order-tag {
    /* width: 139px; */
    color: #514A41;

    font-family: "MADE TOMMY";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 160% */
    letter-spacing: -0.2px;
    /* margin-right: 40px; */
    align-items: start;
}

.confirm-order {
    /* width: 139px; */
    color: var(--New-Primary, #252A4D);
    font-family: "MADE TOMMY";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 145.455% */
    align-items: start;
    letter-spacing: -0.22px;
    align-content: start;

}

.approve-comment {
    color: #100e0c;
    background: #F0FDF9;

    font-family: "MADE TOMMY";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: -0.1px;
    align-items: start;
}

.reject-comment {
    color: #100e0c;
    background: #FEF3F2;

    font-family: "MADE TOMMY";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: -0.1px;
    align-items: start;
}


/* .anticon-close { */
/* background-color: red;
 color: white;
 border-radius: 5px; */
/* } */

.tag {
    padding: 3.867px 7.734px;
    align-items: center;
    gap: 0px;
    border-radius: 348.021px;
    background: #F4F1FD;
}

.stats-cards {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
}

.stats-cards:hover {
    box-shadow: none;
}

@keyframes shake {
    0% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(-5deg);
    }
}

.shake-animation {
    animation: shake 0.3s ease-in-out infinite;
}

.manage-orders-btn {
    background-color: transparent;
    color: var(--New-Primary) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.413px;
    letter-spacing: 0.571px;
    border: none;
    box-shadow: none;
}

.manage-orders-btn:hover {
    background-color: transparent !important;
    color: #4680F1 !important;
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.ripple-container {
    position: relative;
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ripple-background {
    position: absolute;
    z-index: 1;
}

.ripple {
    animation: ripple 1.5s infinite;
    transform-origin: center;
    z-index: 0;
}

.tab-stats {
    background-color: #F3F3F2;
    display: inline-flex;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    font-size: 10px;
    cursor: pointer;
}

.tab-stats-active {
    border-radius: 5.328px;
    background: var(--New-Primary);
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.107px;
    display: flex;
    padding: 5.328px 15.983px;
    justify-content: center;
    align-items: center;
    gap: 5.328px;
}

@keyframes smokeAnimation {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    50% {
        transform: translateY(-20px);
        opacity: 1;
    }

    100% {
        transform: translateY(-40px);
        opacity: 0;
    }
}

#smoke1,
#smoke2,
#smoke3 {
    animation: smokeAnimation 4s ease-in-out infinite;
    transform-origin: center bottom;
}

@keyframes breadLoadingAnimation {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

#bread {
    animation: breadLoadingAnimation 2s ease-in-out infinite alternate;
}

@media screen and (max-width: 1400px) {
    .ant-layout-sider-collapsed .ant-layout-sider-trigger {
        display: none !important;
    }
}

.input-profile {
    cursor: not-allowed;
}

.input-profile:hover {
    border: none !important;
    cursor: not-allowed;
}

.input-profile:focus {
    border: none !important;
    cursor: not-allowed;
}

.input-profile:focus-visible {
    border: none !important;
    cursor: not-allowed;
}

.not-found-title {
    color: var(--Black-60, var(--Nibbles-GRAY, #45464E));
    font-family: "MADE TOMMY";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.not-found-title-txt {
    color: var(--Black-60, var(--Nibbles-GRAY, #45464E));
    font-family: "MADE TOMMY";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: "40px";
    width: 500px;
}

.not-found-title-subtxt {
    color: var(--Black-60, var(--Nibbles-GRAY, #45464E));
    font-family: "MADE TOMMY";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cart-table-header-style{
    color: var(--New-Primary, #252A4D);
font-family: "MADE TOMMY";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}

.avatar-upload-btn{
    background-color:#F2F4FE;
     color:#1347F5;
     border:1px solid #1347F5
}
.upload-image-avatar > .anticon svg{
    color:var(--New-Primary);
}