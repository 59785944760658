.page-cover {
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* background-color: red; */
}

.loader-img-div {
    width: 900px;
    height: 400px;
    display: flex;
    justify-content: center;
    /* background-color: blue; */
    flex-direction: column;
    position: relative;
    text-align: center;
}

/* .loader-img {
    width: auto;
    height: 100%;
    object-fit: contain;
} */

.inner-page-div {
    /* width: 300px; */
    text-align: center;
    font-size: 12px;
}

.random-text-div {
    padding: 10px 0px;
    position: absolute;
    right: 0px;
    left: 40px;
    bottom: 0px;
    top: 220px;
    text-align: center;
}

.random-text-div>p {
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;
    ;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}