@import url('https://fonts.cdnfonts.com/css/made-tommy-outline');
@import url('https://fonts.cdnfonts.com/css/dm-sans');
@import url('https://fonts.cdnfonts.com/css/inter');
@import url('https://fonts.cdnfonts.com/css/poppins');

.queueheader {
    display: flex;
    align-items: center;
}

.queueheaderlogo {
    text-align: start;
    padding: 2em 3em 3em 2em;
}

.queueordertabs {
    background-color: #FFF;
    margin-top: .2%;
}

.queueordertitle {
    color: var(--color-text-primary, #121217);
    font-family: "MADE TOMMY";
    font-size: medium;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 30px */
    letter-spacing: 0.262px;
    text-align: center;
    margin-top: .7%;
}

.queueorderbuttonsdiv {
    display: flex;
    gap: 20px;
    padding: 0em 0em 1em 2em;
}

.queueorderbuttonsselected {
    /* border-radius: 28.088px; */
    background: #EAEBEF;
    color: #2B315A;
    font-family: MADE TOMMY;
    font-weight: 500;
    line-height: normal;
}

.queueorderbuttons {
    /* border-radius: 28.088px; */
    color: #86868B;
    font-family: MADE TOMMY;
    font-weight: 500;
    line-height: normal;
}

.queueviewallprocessingbutton {
    /* border-radius: 28.088px; */
    color: #4D4D4D;
    background: var(--color-border-accent-yellow, #FFDA85);
    font-family: MADE TOMMY;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
}

.queueviewallavailablebutton {
    /* border-radius: 28.088px; */
    color: #4D4D4D;
    background: var(--color-border-accent-green, #B2EECC);
    font-family: MADE TOMMY;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
}

.firstqueuecardcontentdiv {
    /* border-radius: 28.088px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.processingqueuecardorderid {
    /* border-radius: 28.088px; */
    color: var(--Gray-Scale-Gray-900, #1A1A1A);
    font-family: "Inter";
    letter-spacing: 0.1px;
}

.processingqueuecardname {
    /* border-radius: 28.088px; */
    color: var(--Dark-Grey, #5A5A65);
    font-family: "MADE TOMMY";
    letter-spacing: 0.1px;
}