/* .ant-btn.css-dev-only-do-not-override-1rqnfsa.ant-btn-primary {
    background-color: red;
    color: red;
} */

.createproducttbutton {
    border-radius: 3.166px;
    background: var(--New-Primary, #252A4D);
    color: white;
}

.rolecancelButton {
    border-radius: 4px;
background: var(--Off-white, #E6E6E6);
color: var(--Primary, #40499D);
}

.rolesteptitle {
    color: var(--Primary, #40499D);
/* font-family: "Open Sans"; */
}

.roledetailstext {
    color: #3B3B42;
font-size: small;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.roledetailsdescription {
    color: var(--Card-subheader, #919191);
    font-size: small;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}