.custom-disabled-button {
    background-color: #d9d9d9 !important; /* Change this to your desired disabled color */
    color: rgba(255, 255, 255, 0.5) !important; /* Change text color for disabled state */
    border-color: #d9d9d9 !important; /* Change border color if needed */
  }
  
  .custom-disabled-button:hover {
    background-color: #d9d9d9 !important; /* Ensure hover state remains consistent */
    color: rgba(255, 255, 255, 0.5) !important; /* Ensure text color remains consistent */
  }
  
.export-btn:hover{
  background-color: rgb(243, 246, 253) !important;
}